import React, { useState } from 'react';
import { Button, Input } from 'antd';
import * as yup from 'yup';

import { DataSource, AppModel } from '@essenza/core';
import { useControl, useForm, Formix, FormixItem } from '@essenza/react';

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

function LoginRecoverController(c) {
    c.skin = LoginRecover;
    c.state = null;
    c.command = {
        RECOVERY: async (emailed, { model }) => {
            let form = c.form("loginrec-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                const route = "";
                model.read(AppModel, m => m.passwordRequest(result.data.email, route).then(r => emailed(true)));
            }
        }, 
        RESEND: (d,{model}) =>{
            if(c.state){
                model.read(AppModel, m => m.passwordRequest(c.state.email, c.state.route).then(r => c.navigate("/loginrec")));
            }
        }
    }
}

export function LoginRecover({ vmodel }) {
    const [control] = useControl(LoginRecoverController);
    const [emailed, setEmailed] = useState(false);
    const form = useForm("loginrec-form", new DataSource({}), control, null, yup.object({
        email: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
    }));

    return (
        <>
            {!emailed ?
                <Formix control={control} form={form} layout='vertical' className="layout-form">
                    <FormixItem label="E-mail" name="email">
                        <Input placeholder="email di registrazione"></Input>
                    </FormixItem>
                    <FormixItem>
                        <Button className='primaryButton' onClick={() => control.execute("RECOVERY", setEmailed)}>
                            Procedi
                            {<FrecciaBianca />}
                        </Button>
                    </FormixItem>
                    <Button style={{ padding: '0' }} className='bolder h6 primary' type="link" onClick={() => control.navigate("/login")}>Torna a Login</Button>
                </Formix>
                : <>
                    <h6>Controlla la tua posta, abbiamo inviato una mail contenente il link per reimpostare la password.</h6>
                    <p style={{marginTop: '16px'}}>Email non ricevuta? <Button className='bolder h6 primary' type="link" onClick={() => setEmailed(false)}>
                        Invia di nuovo.
                    </Button></p>
                    <div>
                        <Button className='primaryButton' onClick={() => control.navigate("/login")}>
                            Vai al Login
                            {<FrecciaBianca />}
                        </Button>
                    </div>
                </>
            }
        </>
    )
}