import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Vista, useModel, useVista, useGraph } from "@essenza/react";
import { CourseModel } from "../models/CourseModel";

import { CourseDetail } from "../view/course_detail";
import { LessonList } from "../view/lesson_list";

function CourseDetailVistaController(c) {
    c.skin = CourseDetailVista;
}

export function CourseDetailVista() {
    const [ctx] = useVista(CourseDetailVistaController);
    const course = useGraph(CourseModel, "detail");
    const [model] = useModel();
    const id = useLocation().state;

    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(CourseModel, m => m.detail(id,5));
        }
    }, [model, id]);

    return (
        <Vista context={ctx} >
            <div style={{ width: '100%' }}>
                <div style={{ padding: '24px', width: '100%', maxWidth: '800px', margin: '0 auto' }} className=''>
                    <CourseDetail source={course} />
                    <LessonList source={course.get('lessons')} />
                </div>
            </div>
        </Vista>
    )
}