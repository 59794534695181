import { Button, Card, Col, Row } from "antd";
import { Swiper, SwiperSlide } from 'swiper/react';

import { useControl } from "@essenza/react";
import { DataSource } from "@essenza/core";

import { CategoryForm } from "./category_form";
import { MdDeleteForever } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

import 'swiper/css';

const { Meta } = Card;

function CategoryCarouselController(c) {
    c.skin = CategoryCarousel;
    c.command = {
        EDIT: (source) => {
            c.show(<CategoryForm npath="/" ndata="2" label="Aggiorna" source={source} />, { title: 'Modifica Area' });
            //c.openPopup(<CategoryForm source={source} label="Aggiorna" />, "Modifica Area Tematica", null, { excludeOk: true, excludeCancel: true });
        },
        DELETE: (node, { data }) => {
            c.openPopup("Sei sicuro di voler eliminare l'area selezionata?", "Elimina", 400, {
                onconfirm: () => {
                    node.delete(data.id);
                }
            });
        },
    }
}

export function CategoryCarousel({ source, editable }) {

    const [control] = useControl(CategoryCarouselController);
    const actions = (item) => {
        return editable
            ? [<Button onClick={() => control.execute("EDIT", new DataSource(item, source.node))} className="primaryButton">Modifica<RiEdit2Fill /></Button>,
            <Button onClick={() => control.execute("DELETE", source.node, item)} className="secondaryButton">Elimina<MdDeleteForever /></Button>]
            : null;
    }
    if (!source.data) return null;
    return (
        <>
            <Row align="middle">
                <Col className="custom-secondary-header">
                    <h2 style={{ marginBottom: "0" }}>Le Aree</h2>
                </Col>
                {!editable && <Col offset={1} >
                    <Button onClick={() => control.navigate("/corsi")} className="tertiaryButton">
                        Vedi tutti i corsi
                        {<FrecciaBianca />}
                    </Button>
                </Col>}
            </Row>
            <Swiper spaceBetween={16} slidesPerView={1.2} breakpoints={
                // when window width is >= 320px
                {
                    // when window width is >= 480px
                    720: {
                        slidesPerView: 2.2,
                        spaceBetween: 24
                    },
                    // when window width is >= 640px
                    992: {
                        slidesPerView: 3.2,
                        spaceBetween: 24
                    }
                }
            }>
                {
                    source.getCollection()
                        ? source.getCollection().map(item =>
                            <SwiperSlide className="pointer" onClick={() => control.navigate("/corsi", { state: item.id })}>
                                <img src={"/cover/" + item.id + ".jpg"} style={{ width: '100%' }} alt="Copertina Aree Tematiche" />
                                <div className="cover"></div>
                                <div className="bottom-left h6">{item.tname}</div>
                            </SwiperSlide>
                        )
                        : null
                }
            </Swiper>

        </>
    )
}