import { DataModel } from "@essenza/core";
import { domains } from "../view/signin_form";

export function CourseModel() {
  DataModel.call(this, "course");
  this.item = (id) => {
    this.ExecuteQuery(`item: course (id=@id) {*,
      lessons: [lesson] {*, -O iorder},
      #join: category (id=idcategory) {tname}, -O id -D
    }`, { id: id });
  };

  this.list = () => {
    return this.ExecuteQuery(`list: [course]  {*, -O id -D
    }`)
  }

  this.student = (userid, limit) => {
    if (limit === undefined) limit = 0;
    this.ExecuteApi("student: course {*}", { limit: limit });
  }

  this.all = (domain) => {
    this.ExecuteApi("all: course {*}", { domain: domains.indexOf(domain) }, "allcourse");
  }

  this.suggest = (domain) => {
    this.ExecuteApi("suggest: course {*}", { domain: domains.indexOf(domain) }, "suggestcourse");
  }

  this.detail = (courseid) => {
    this.ExecuteApi("detail: course {*}", { courseid: courseid }, "cdetail");
  }

  this.sort = (pos, lpos, idcourse, idlesson) => {
    this.ExecuteApi("sortlesson: course {*}", { pos: pos + 1, lpos: lpos + 1, idcourse: idcourse, idlesson: idlesson });
  }
}
