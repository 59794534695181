import { Button, Col, Row } from "antd";
import { useEffect } from "react";

import { Vista, useVista, useModel, useApp, useGraph } from "@essenza/react";

import { CourseCarousel } from "../view/course_carousel";
import { CourseList } from "../view/course_list";
import { CourseModel } from "../models/CourseModel";
import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

function StudentVistaController(c) {
    c.skin = StudentVista;
}

export function StudentVista() {
    const [ctx, control] = useVista(StudentVistaController);
    const app = useApp();
    const courses = useGraph(CourseModel, "student");
    const suggest = useGraph(CourseModel, "suggest");
    const [model] = useModel();

    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(CourseModel, m => m.student(5));
            model.read(CourseModel, m => m.suggest(app.session.email.split('@')[1]));
        }
    }, [model]);

    return (
        <Vista context={ctx} >
            <div className='content-max-width'>
                <div className='block-max-width centered'>
                    <Row className='content-max-width' gutter={28} justify="space-between">
                        <Col span={14} style={{ padding: '24px 24px 0 24px' }} className="custom-column-vertical-center-alignment">
                            <h2 style={{ marginBottom: "0", paddingLeft: '12px' }}>I miei Corsi</h2>
                        </Col>
                    </Row>
                    <CourseList source={courses} span="1" />
                    {suggest.data &&
                        <>
                            <Row style={{ marginTop: "32px" }} align="middle" >
                                <Col>
                                    <h6 style={{ paddingLeft: "24px", marginBottom: "0" }}>Altri corsi che potrebbero interessarti</h6>
                                </Col>
                                <Col className="pt-col" style={{ paddingLeft: "24px" }}>
                                    <Button onClick={() => control.navigate("/corsi")} className="tertiaryButton">
                                        Vedi tutti i corsi
                                        {<FrecciaBianca />}
                                    </Button>
                                </Col>
                            </Row>
                            <CourseCarousel source={suggest} />
                        </>
                    }
                </div>
            </div>
        </Vista>
    )
}