import { Col, Input, Row, Select } from "antd";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Vista, useModel, useVista, useGraph, useApp } from "@essenza/react";
import { CourseModel } from "../models/CourseModel";
import { CategoryModel } from "../models/categorymodel";

import { CourseList } from "../view/course_list";
import { ReactComponent as SearchIcon } from '../assets/svg/search-icon.svg';

const { Option } = Select;

function AllCourseController(c) {
    c.skin = AllcourseVista;
    let store = null;
    c.command = {
        SEARCH: (list, { data }) => {
            if (!store)
                store = list;
            const i = data.index;
            const txt = data.text.toLowerCase();
            data.index = 0;
            c.setSource("course.all", store.filter((item) => (txt === '' || item.title.toLowerCase().indexOf(txt) > -1) && (i === 0 || i === item.idcategory)));
        }
    }
}

export function AllcourseVista() {
    const [ctx, control] = useVista(AllCourseController);
    const app = useApp();
    const courses = useGraph(CourseModel, "all");
    const categories = useGraph(CategoryModel, "list");
    const [model] = useModel();
    const filter = useRef({ index: 0, initialized: false, text: '' }).current;
    const idcategory = useLocation().state;
    useEffect(() => {
        console.log("MODEL ", model, app.session.email);
        if (model) {
            model.read(CourseModel, m => m.all(app.session.email.split('@')[1])); //condition state = 'P' (published)
        }
    }, [model]);

    console.log("AREE-DEBUG", categories);
    if (!courses.data) return null;

    if (!filter.initialized ) {
        filter.initialized = true;
        if (idcategory) {
            filter.index = idcategory;
            control.execute("SEARCH", courses.data, filter);
        }
    }

    return (
        <Vista context={ctx} >
            <div className='content-max-width'>
                <div className='block-max-width centered'>
                    <Row className='content-max-width' gutter={28} justify="space-between">
                        <Col span={24} md={5} className="custom-column-vertical-center-alignment p-media">
                            <h2 className="pl-media " style={{ marginBottom: "0" }}>Tutti i Corsi</h2>
                        </Col>
                        <Col span={24} md={10} className="p-media" >
                            <Select defaultValue={idcategory} onChange={(v) => { filter.index = v; control.execute("SEARCH", courses.data, filter) }} placeholder="Aree" style={{ width: '100%', maxWidth: '690px' }}>
                                <Option value={0}>Tutte le Aree</Option>
                                {categories.data && categories.data.map((item) => <Option value={item.id}>{item.tname}</Option>)}
                            </Select>
                        </Col>
                        <Col span={0} md={9} className="p-media" >
                        <Input onChange={(e) => { filter.text = e.currentTarget.value; control.execute("SEARCH", courses.data, filter) }} prefix={<SearchIcon />} placeholder="Cerca corso" className="search-filter">
                        </Input>
                        </Col>
                    </Row>
                    <CourseList source={courses} />
                </div>
            </div>
        </Vista >
    )
}