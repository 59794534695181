import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Button, Input } from 'antd';

import { DataSource, AppModel } from '@essenza/core';
import { useControl, useForm, Formix, FormixItem } from '@essenza/react';

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

function LoginController(c) {
    c.skin = Login;
    c.command = {
        LOGIN: async (name,{model, app}) => {
            name = name || "login-form";
            const result = await c.form(name).validate();
            if(result.isValid){
                let data = result.values;
                if(!data.email || !data.password){
                    
                    let instance = result.form.getFieldInstance("email");
                    instance.focus();
                    console.log("LOGIN-DATA-A", data, instance);
                    setTimeout(()=>{
                        instance = result.form.getFieldInstance("password");
                        instance.focus();
                        setTimeout(()=>{
                            data = result.form.getFieldsValue(true);
                            console.log("LOGIN-DATA", data);
                            model.request(AppModel, m=>m.login(data.email, data.password)).then(r=>{
                                app.login(r);
                            });
                        }, 1000);
                    }, 1000);
                }
                else{
                    console.log("LOGIN-DATA-OK", data);
                    model.request(AppModel, m=>m.login(data.email, data.password)).then(r=>{
                        app.login(r);
                    });
                }
            }
        }
    }
}

export function Login({ nosignin }) {

    const [control] = useControl(LoginController);
    const form = useForm("login-form", new DataSource({}), control, null, yup.object({
        email: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
        password: yup.string().required("Password è una informazione richiesta."),
        //confirm_password: yup.string().label('conferma password').required().oneOf([Yup.ref('password'), null], 'Password non corrispondenti'),
    }));

    useEffect(()=>{
        let instance = form.target.getFieldInstance("email");
                    instance.focus();
    }, []);

    return (
        <Formix control={control} form={form} layout='vertical' className="layout-form">
            <FormixItem label="E-mail" name="email">
                <Input placeholder="email"></Input>
            </FormixItem>
            <FormixItem label="Password" name="password">
                <Input.Password onPressEnter={() => control.execute("LOGIN", "login-form")} placeholder="password"></Input.Password>
            </FormixItem>
            <Button style={{float: 'right'}} type="link" onClick={() => control.navigate("/loginrec")}>
                    Password dimenticata?
                </Button>
            <FormixItem>
                <Button className='primaryButton' onClick={() => control.execute("LOGIN", "login-form")}>
                    Accedi
                    {<FrecciaBianca />}
                </Button>
            </FormixItem>
            {!nosignin && <p className="">Primo accesso? <Button className='bolder h6 primary' type="link" onClick={() => control.navigate("/signin")}>
                Registrati
            </Button></p>}
        </Formix>
    )
}