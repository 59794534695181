
import './app.scss';

import { version, Test } from '@essenza/core';
import { AppRoot, useBreakPoint } from '@essenza/react';

import React, { useEffect, useMemo, useRef } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { MainLayout } from './layout/MainLayout';

import { AppSchema } from './schema';
import { LoginVista } from './vista/LoginVista';
import { SigninVista } from './vista/SigninVista';
import { RecoverVista } from './vista/RecoverVista';
import { Welcome } from './vista/Welcome';

import { OverviewVista } from './vista/OverviewVista';
import { StudentVista } from './vista/StudentVista';
import { CourseDetailVista } from './vista/CourseDetailVista';
import { LessonVista } from './vista/LessonVista';
import { AllcourseVista } from './vista/AllcourseVista';
import { UserFormVista } from './vista/UserFormVista';
import { FormVista } from './vista/FormVista';
import { FirstAccessVista } from './vista/FirstAccessVista';
import { CategoryModel } from './models/categorymodel';
import { ProfileVista } from './vista/ProfileVista';
import { QuestionnaireVista } from './vista/Questionnaire';

/**
 * @param {VistaApp} app 
 */
function initApp(app) {
  console.log("INIT APP", app);
  return null;
}

/**
 * @param {VistaApp} app 
 */
function onlogin(app) {
  console.log("ON LOGIN", app);
  app.model.read(CategoryModel, m=>m.list(null, true));
  app.navigate("/home");
}

function AppController(c) {
  c.skin = App;
  c.command = null;
}
//https://localhost:44379/      https://app.semprecontebcc.com/      https://semprecontebcc.spritzy.it
function App() {
  console.log("APP-PROVIDER-BP", version, Test);
  const nav = useNavigate();
  const [qp] = useSearchParams();
  const token = useRef();
  //const breakpoint = useBreakPoint('md');

  return (
    <AppRoot token={token} init={initApp} control={AppController} navigator={nav} qp={qp} onlogin={onlogin} baseurl="https://semprecontebcc.com/" schema={AppSchema}>
      <Routes>
        <Route path="/" element={<MainLayout token={token} />}>
          <Route index element={<Welcome token={token} />} />
          <Route path="home" element={<OverviewVista />} />
          <Route path="overview" element={<OverviewVista />} />
          <Route path="student" element={<StudentVista />} />
          <Route path="coursedetail" element={<CourseDetailVista />} />
          <Route path="lessondetail" element={<LessonVista />} />
          <Route path="corsi" element={<AllcourseVista />} />
          <Route path="questionario" element={<QuestionnaireVista />} />
          <Route path="userform" element={<UserFormVista />} />
          <Route path="formvista" element={<FormVista />} />
          <Route path="signin" element={<SigninVista />} />
          <Route path="loginrec" element={<RecoverVista />} />
          <Route path="primoaccesso" element={<FirstAccessVista />} />
          <Route path="profile" element={<ProfileVista />} />
          <Route path="login" element={<LoginVista />} />
          <Route path="*" element={<OverviewVista />} />
        </Route>
      </Routes>
    </AppRoot>
  );
}

export default App;
