import { useLocation } from "react-router-dom";
import { Vista, useVista, useGraph } from "@essenza/react";
import { UserForm } from "../view/user_form";
import {  UserModel } from "../models/usermodel";

function UserFormController(c) {
    c.skin = UserFormVista;
}

export function UserFormVista() {
    const [ctx] = useVista(UserFormController);
    const user = useGraph(UserModel, "item");
    const state = useLocation().state;
    return (
        <Vista context={ctx} >
            <UserForm label={state?.label} isUpate={state?.isUpate} npath="settings" source={user} />
        </Vista>
    )
}