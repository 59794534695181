import { Button, Row } from "antd";

import { useControl, ECVideoPlayer } from "@essenza/react";

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';
import { ReactComponent as Arrow } from '../assets/svg/icona_indietro.svg';

function LessonViewController(c) {
    c.skin = LessonView;
    c.command = {
        SHIFT: (pos, {data} ) => {
            const item = data.source.data;
            const lessons = data.source.getData("lessons");
            //pos--;
            //debugger;
            if(data.direction === 'R'){
                if(pos < lessons.length)
                    c.navigate("/lessondetail", {state: lessons[pos].id});//GO NEXT
            }
            else if(pos>1)
                c.navigate("/lessondetail", {state: lessons[pos-2].id}); 
        }
    }
}

//<Button onClick={()=>control.navigate("/overview")}><CaretLeftOutlined /></Button>

export function LessonView({ source, progressive }) {
    const [control] = useControl(LessonViewController);

    const lesson = source.data;
    if (!lesson) return null; //loader??? qui o in vista o comunque chi lo usa

    console.log("progressive",progressive);

    return (
        <>
            <h6 className="h6-single-lesson elements-spacing-b16"><Arrow className="pointer svg-ter" onClick={()=>control.navigate("/student")} style={{marginRight: '24px'}}/>{lesson.title}</h6>
            <h3 className="elements-spacing-b32">{lesson.iorder + '. ' + lesson.tname}</h3>
            <ECVideoPlayer noforward={progressive} className="centered" style={{width: '100%'}} state={lesson.state} info={{userid: 5, id: lesson.id, idcourse: lesson.idcourse, position: lesson.position}} preload="metadata" controls>
                <source src={"/lesson/" + lesson.id + ".mp4"} type="video/mp4" />
                {/* <source src="/ftbcc.mp4#t=0.001" type="video/mp4" /> */}
            </ECVideoPlayer>
            <Row justify="space-between" style={{marginTop: '8px'}} className="elements-spacing-b48">
                <Button style={{marginTop: '16px'}} onClick={()=>control.execute("SHIFT", lesson.iorder, {direction: "L", source: source})} className="secondaryButton">
                    Lezione precedente
                </Button>
                <Button style={{marginTop: '16px'}} onClick={()=>control.execute("SHIFT", lesson.iorder,{direction: "R", source: source})} className="primaryButton">
                    Prossima Lezione
                    {<FrecciaBianca />}
                </Button>
            </Row>
            {
                lesson.tdescription && <section>
                    <h6 className="elements-spacing-b16">Dettaglio video</h6>
                    <p style={{textAlign: 'justify'}}>{lesson.tdescription}</p>   
                </section>
            }
        </>
    )
}