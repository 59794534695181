import { useRef } from "react";
import { Button, Col, Progress, Row } from "antd";
import { Swiper, SwiperSlide } from 'swiper/react';

import { useControl } from "@essenza/react";

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

import 'swiper/css';

function CoursesListController(c) {
    c.skin = CourseCarousel;
    c.command = {

    }
}

export function CourseCarousel({ source, span }) {
    span = span || 2;
    const [control] = useControl(CoursesListController);
    const labels = ['Inizia', 'Continua', 'Vai al Corso'];
    const categories = useRef(control.getSource("category.list")).current;

    const progress = (count, total, item) => {
        if (count === 0) {
            item.index = 0;
            return ' ';//'0% Completato';
        }
        else if (count < total) {
            item.index = 1;
            return <div><Progress trailColor="#8B9CCE" strokeColor="#264395" percent={count / total * 100} steps={total || 2} /> Completato</div>//<h5>Completato</h5>
        }
        else {
            item.index = 2;
            return <div><Progress trailColor="#8B9CCE" strokeColor="#264395" percent={count / total * 100} steps={total || 2} /> Completato</div>;
        }
    }

    if (!source.data) return null;
    //{cover "/lesson/" + item.lessonid + ".jpg"} , height: h + 'px'
    return (
        <>
            <div className="" style={{ padding: '24px' }}>
                <Swiper spaceBetween={16} slidesPerView={1.1} >
                    {
                        source.getCollection()
                            ? source.getCollection().map(item =>
                                <SwiperSlide>
                                    <Row className="bg-sec" justify="center" align="middle" style={{ padding: '32px', borderRadius: '4px' }}>
                                        <Col span={24} md={9} ><img src={"/lesson/" + item.lessonid + ".jpg"} className="img-scale" alt="Copertina Corso" /></Col>
                                        <Col span={24} md={15} className="col-max-width">
                                            <div className="pt-col ts-pri">{categories ? categories.find((c)=>c.id === item.idcategory)?.tname : ''}</div>
                                            <h6>{item.title}</h6>
                                            <div className="mt-1">{progress(item.count, item.total, item)}</div>
                                            <div style={{ marginTop: '12px' }}><Button onClick={() => control.navigate("/coursedetail", { state: item.id })} className="primaryButton">{labels[item.index]}<FrecciaBianca /></Button></div>
                                        </Col>
                                    </Row>
                                </SwiperSlide>
                            )
                            : null
                    }
                </Swiper>
            </div>
        </>
    )
}