import React, { useEffect } from "react";
import { Row, Col, Button } from 'antd';

import { Vista, useVista, useModel, useGraph } from "@essenza/react";
import { CourseModel } from "../models/CourseModel";
import { CategoryModel } from "../models/categorymodel";

import { CategoryCarousel } from "../view/category_carousel";
import { CourseCarousel } from "../view/course_carousel";
import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';
import DemoScreen from '../assets/img/demoscreen.jpg';

function OverviewVistaController(c) {
    c.skin = OverviewVista;
}

export function OverviewVista() {
    const [ctx, control] = useVista(OverviewVistaController);
    const categories = useGraph(CategoryModel, "list");
    const courses = useGraph(CourseModel, "student");
    const [model] = useModel();
    console.log("OVERVIEW-DEBUG", categories);
    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            //model.read(CategoryModel, m => m.list());
            model.read(CourseModel, m => m.student(5, 2));
        }
    }, [model]);

    return (
        <Vista context={ctx} >
            <Row className="content-max-width" >
                <Col span={24} lg={12} className="column-bg">
                    <div className="centered" style={{ maxWidth: '530px' }} >
                        <h1 style={{ paddingTop: '24px' }} className="elements-spacing-b32">La nostra idea di formazione</h1>
                        <video className="centered img-resize" preload="metadata" controls >
                            <source src="/ftbcc.mp4#t=0.001" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <p style={{ fontWeight: "300", textAlign: 'justify', marginTop: '24px' }}>
                            Come Federazione Toscana BCC ti diamo il benvenuto nella
                            piattaforma “Sempre con te”, una piattaforma didattica digitale video
                            realizzata esclusivamente per il Credito Cooperativo toscano.
                        </p>
                        <p style={{ fontWeight: "300", textAlign: 'justify' }}>
                            Abbiamo imparato a far tesoro del nostro tempo e per questo
                            abbiamo pensato di avvicinarti alla formazione “sempre e ovunque”.
                            Potrai formarti quando e dove lo ritieni opportuno e tenere
                            aggiornate le tue competenze sia tecniche che relazionali e
                            manageriali.
                        </p>
                        <p style={{ fontWeight: "300", textAlign: 'justify' }}>
                            Il digitale ha cambiato e continuerà a cambiare la formazione e ci sta
                            guidando verso una nuova era della formazione dove la distanza tra
                            fisico e digitale si accorcia.
                        </p>
                        <p style={{ fontWeight: "300", textAlign: 'justify' }}>
                            La piattaforma “Sempre con te” è lo strumento di auto
                            apprendimento per le BCC toscane che unisce le opportunità offerte
                            dalle nuove tecnologie con un approccio personalizzato alla
                            formazione.
                        </p>
                        <p style={{ fontWeight: "300", textAlign: 'justify' }}>
                            Essere <i>sempre con te e supportarti nel tuo lavoro</i> è lo spirito della
                            piattaforma di FTBCC che ti permette di fruire di tutte le pillole
                            didattiche presenti.
                        </p>
                    </div>
                </Col>
                <Col span={24} lg={12} className="column-right-50">
                    <div className="padding-md">
                        <CategoryCarousel source={categories} />
                    </div>
                    <Row style={{ marginTop: "32px" }} align="middle" >
                        <Col>
                            <h2 style={{ paddingLeft: "24px", marginBottom: "0" }}>I miei corsi</h2>
                        </Col>
                        <Col offset={1}>
                            <Button onClick={() => control.navigate("/student")} className="tertiaryButton">
                                Vedi tutti i miei corsi
                                {<FrecciaBianca />}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <CourseCarousel source={courses} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Vista>
    )
}

