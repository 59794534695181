import { Outlet, useLocation } from "react-router-dom";
import { Layout, Row, Col, Menu, Avatar, Tooltip } from "antd";

import { ReactComponent as Video } from '../assets/svg/video.svg';
import { ReactComponent as Myvideo } from '../assets/svg/myvideo.svg';
import { ReactComponent as Home } from '../assets/svg/home.svg';

import { UserOutlined } from '@ant-design/icons';
import Logo from '../assets/img/logo-ftbcc.png';

import { useBreakPoint } from "@essenza/react";

const { Header, Content } = Layout;
const items = [
  { label: 'Home', key: '/overview' },
  { label: 'I Miei Corsi', key: "/student" },
  { label: 'Tutti I Corsi', key: '/corsi' },
];
export function MainLayout({ token }) {

  const breakpoint = useBreakPoint('lg');
  const control = token.current?.control;
  const logged = token.current.logged;
  const location = useLocation();

  const onClick = (e) => {
    console.log('click ', e);
    control.navigate(e.key);
  };
  return (
    <Layout className="layout">
      <Layout className="layout">
        <Header className="layout-header">
          {logged && <Row>
            <Col flex="none">
              <img src={Logo} style={{ verticalAlign: "top" }} alt="Logo" /> 
            </Col>
            {breakpoint.lg.active
              ?<Col flex="auto">
                <Menu onClick={onClick} className="layout-bg header-menu" items={items} mode="horizontal" selectedKeys={location?.pathname}></Menu>
              </Col>
              : <>
                <Col flex="none" className="avatar-column">
                  <Tooltip placement="bottom" title="Home" color="#264395">
                    <Home  className={'pointer ' + (location?.pathname==='/overview'?'iselected':'')} onClick={() => control.navigate("/overview")} />
                  </Tooltip>
                </Col>
                <Col flex="none" className="avatar-column">
                  <Tooltip placement="bottom" title="I Miei Corsi" color="#264395">
                    <Myvideo className={'pointer ' + (location?.pathname==='/student'?'iselected':'')} onClick={() => control.navigate("/student")} />
                  </Tooltip>
                </Col>
                <Col flex="auto" className="avatar-column">
                  <Tooltip placement="bottom" title="Corsi" color="#264395">
                    <Video className={'pointer ' + (location?.pathname==='/corsi'?'iselected':'')} onClick={() => control.navigate("/corsi")} />
                  </Tooltip>
                </Col>
              </>
            }
            <Col className="avatar-column">
              <Tooltip placement="bottom" title="Profilo" color="#264395">
                <Avatar className="pointer" onClick={() => control.navigate("/profile")} size={36} icon={<UserOutlined />} />
              </Tooltip>
            </Col>
          </Row>}
        </Header>
        <Content
          className=" layout-content"
          style={{
            padding: 0,
            minHeight: 280,
          }}
        >
          <Outlet></Outlet>
        </Content>
      </Layout>
    </Layout>
  );
}