import { Vista, useVista } from "@essenza/react";
import { FirstAccess } from "../view/login_firstaccess";

function FirstAccessVistaController(c) {
    c.skin = FirstAccessVista;
}

export function FirstAccessVista() {
    const [ctx] = useVista(FirstAccessVistaController);
    /*const token = useLocation().state?.get("fareq");
    const valid = useGraph2(AppModel, "validate");
    const [model] = useModel();
    
    useEffect(() => {
        if (token && model) {
            model.read(AppModel, m => m.validate(token)); //Api service e passo setState per ricevere il risultato
        }
    }, [token, model]);

    let content;
    if (valid.data === null)
        content = <Spin />;
    else if (valid.data)
        content = <Row className='content-max-width h-main padding-sm' align="middle">
            <Col span={24} className="bg-sec padding-xl">
                <img src={Logo} style={{ verticalAlign: "top", width: "194px" }} alt="Logo Banca bbc" className="elements-spacing-b16" />
                <h1>
                    Crea Password
                </h1>
                <FirstAccess />
            </Col>
        </Row>;
    else
        content = <p>Richiesta scaduta.</p>;*/

    return (
        <Vista context={ctx} >
            <div className='content-max-width'>
                <div className='block-middle-width centered'>
                    <FirstAccess />
                </div>
            </div>
        </Vista>
    )
}