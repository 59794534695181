import React from 'react';
import * as yup from 'yup';
import { Button, Input } from 'antd';

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

import { DataSource, AppModel, AppService } from '@essenza/core';
import { useControl, useForm, Formix, FormixItem } from '@essenza/react';

export const domains = ['bvlg.bcc.it', 'bancavaldarno.bcc.it', 'bancacentro.bcc.it', 'bancadipisa.it', 'bancaelba.it', 'bancatema.bcc.it', 'bancofiorentino.it', 'bat.bcc.it',
    'bccas.it', 'bccvaldarnofiorentino.it', 'bpc.bcc.it', 'chiantibanca.it', '@creasvilupposb.bcc.it', 'ft.bcc.it', 'pontassieve.bcc.it', 'vivalbanca.bcc.it', 'gmail.com'];

function SigninController(c) {
    c.skin = Signin;
    c.command = {
        SIGNIN: async (name, {model, app}) => {
            name = name || "signin-form";
            const result = await c.form(name).validate();
            if(result.isValid){
                const data = result.values;
                console.log("LOGIN-DATA", data);
                model.request(AppModel, m=>m.signin(data.email, data.password)).then(r=>{
                    app.login(r);
                });
            }
        }
    }
}

export function Signin() {
    const [control] = useControl(SigninController);
    const form = useForm("signin-form", new DataSource({}), control, null, yup.object({
        email: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto").test("domain", "Dominio email non supportato",v=>{
            if(!v || v.indexOf('@') === -1) return false;
            else{
                return domains.includes(v.split('@')[1].trim())
            }
        }),
        password: yup.string().required("Password è una informazione richiesta.").matches(
            ///^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&(){}[]:;<>,.?~_+-=|\]).{8,32}$/,
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,24}$/,
            "Deve contenere Almeno 8 Caratteri, almeno una maiuscola, almeno una minuscola, almeno un umero ed almeno un carattere speciale"
          ),
          cpassword: yup.string().required("Conferma password richiesta.").test('passwords-match', 'Passwords non corrispondenti', function(value){
            console.log("YUP", this, this.parent, value);
            return this.parent.password === value;
          }),
    }));

    return (
        <Formix control={control} form={form} validateTrigger="onBlur" layout='vertical' className="layout-form">
            <FormixItem label="E-mail" name="email">
                <Input placeholder="email"></Input>
            </FormixItem>
            <FormixItem label="Crea Nuova Password" name="password">
                <Input.Password placeholder="password"></Input.Password>
            </FormixItem>
            <FormixItem label="Conferma Password" name="cpassword">
                <Input.Password placeholder="conferma password"></Input.Password>
            </FormixItem>
            <FormixItem>
                <Button className='primaryButton' onClick={() => control.execute("SIGNIN")}>
                    Registrati
                    {<FrecciaBianca />}
                </Button>
            </FormixItem>
            <p className="">Già registrato? <Button className='bolder h6 primary' type="link" onClick={() => control.navigate("/login")}>
                Accedi
            </Button></p>
        </Formix>
    )
}