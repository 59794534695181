import { Button } from 'antd';
import Logo from '../assets/img/logo-ftbcc.png';

import { Vista, useVista } from "@essenza/react";

import { FirstAccess } from '../view/login_firstaccess';

function WelcomeController(c) {
    c.skin = Welcome;
}

export function Welcome({content, token}) {
    const [ctx] = useVista(WelcomeController);
    const app = token.current;

    if (!app.irequest)
        content = content || <>
            {Logo && <img src={Logo} className="centered" style={{ marginTop: "120px" }} alt="Logo" />}
            {/* <Spin /> */}
            <Button className="centered" style={{ fontSize: '2.6em' }} type="link" onClick={() => app.navigate("/login")}>Vai al Login</Button>
        </>;
    else if (app.irequest.type === "FA")
        content = <FirstAccess request={app.irequest} />;
    else
        content = <>
            {Logo && <img src={Logo} className="centered" style={{ marginTop: "120px" }} alt="Logo" />}
            <Button className="centered" style={{ fontSize: '2.6em' }} type="link" onClick={() => app.navigate("/login")}>Vai al Login</Button>
        </>;

    return (
        <Vista context={ctx} >
            <div className='content-max-width'>
                <div className='block-middle-width centered'>
                    {content}
                </div>
            </div>
        </Vista>
    )
}