import { Col, Button, Input,  Radio, Row } from 'antd';
import React from "react";
import * as yup from 'yup';

import { AppModel } from "@essenza/core";
import { Formix, FormixItem, useForm, useControl } from "@essenza/react";

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';


function UserFormController(c) {
    c.skin = UserForm;
    c.command = {
        SAVE: async (path, { control, model }) => {
            //console.log("SAVE", source);
            let form = control.form("user-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                result.node.save().then((r) => {
                    if (path)
                        control.navigate(path);
                    else
                        control.closePopup();
                    //result.node.sync(result.data);
                    //model.refresh("users.list");
                });
                //In futuro dovrà avvenire automaticamente in datagraph model, così come save su error ripristina dati originali
            }
        },
        INVITEIN: async (route, { model }) => {
            let form = c.form("user-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                route = !result.data.itype ? "/admin" : "";
                model.read(AppModel, m => m.invitein(result.data, route).then(r => c.navigate("/settings")));
            }
        }
    }
}

export function UserForm({ source, label, isUpdate, title, npath, route }) {
    //const source = useGraph2(LessonModel, "item");
    const [control] = useControl(UserFormController);
    const form = useForm("user-form", source, control, null, yup.object({
        email: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
    })); //, {itype: (v) => v === "1"}
    const onclick = isUpdate
        ? () => control.execute("SAVE", npath)
        : () => control.execute("INVITEIN", route);
    return (
        <div style={{ maxWidth: '800px', marginTop: '24px' }} className="single-section-custom-2 centered">
            <h2>{title || "Completa l'inserimento"}</h2>
            <Formix control={control} form={form} layout='vertical' className="layout-form">
                <FormixItem label="Nome" name="name">
                    <Input placeholder="Nome"></Input>
                </FormixItem>
                <FormixItem label="Cognome" name="surname">
                    <Input placeholder="Cognome">
                    </Input>
                </FormixItem>
                <FormixItem label="E-mail" name="email">
                    <Input placeholder="email">
                    </Input>
                </FormixItem>
                <FormixItem label="Tipo" name="itype">
                    <Radio.Group defaultValue={1} className="radio-margin">
                        <Radio value={0}>Amministratore</Radio>
                        <Radio value={1}>Utente</Radio>
                    </Radio.Group>
                </FormixItem>
                <Row gutter={28}>
                    <Col flex="auto">
                        <FormixItem className='elements-spacing-b0'>
                            <Button className='secondaryButton' onClick={() => control.navigate(-1, { state: '1' })}>
                                Annulla
                            </Button>
                        </FormixItem>
                    </Col>
                    <Col flex="none">
                        <FormixItem className='elements-spacing-b0'>
                            <Button className="primaryButton" onClick={onclick}>
                                {label || "Inserisci"}
                                <FrecciaBianca />
                            </Button>
                        </FormixItem>
                    </Col>
                </Row>
                {/* <Form.Item className='elements-spacing-b0'>
                        <Button className="primaryButton" onClick={onclick}>
                            {label || "Inserisci"}
                            <FrecciaBianca />
                        </Button>
                    </Form.Item> */}

            </Formix>
        </div>

    )
}