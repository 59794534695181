import { Col, Row } from "antd";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Vista, useModel, useVista, useGraph } from "@essenza/react";
import { LessonView } from "../view/lesson_view";
import { LessonModel } from "../models/lessonmodel";
import { LessonList } from "../view/lesson_list";
import { CourseModel } from "../models/CourseModel";

function LessonVistaController(c) {
    c.skin = LessonVista;
}

export function LessonVista() {
    const [ctx] = useVista(LessonVistaController);
    const lesson = useGraph(LessonModel, "detail");
    const course = useGraph(CourseModel, "detail");
    const [model] = useModel();
    const id = useLocation().state;
    console.log("LESSON STATE ID", id);
    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(LessonModel, m => m.detail(id,5)); //condition state = 'P' (published)
        }
    }, [model, id]);

    return (
        <Vista context={ctx} >
            <Row>
                <Col style={{padding: '24px'}} span={24} lg={16}><LessonView progressive={course?.data?.progressive} source={lesson} /></Col>
                <Col span={24} lg={8}><LessonList currentid={lesson.data?.id} source={lesson.get('lessons')} /></Col>
            </Row>
        </Vista>
    )
}