import { Col, Row } from "antd";

import { Vista, useVista } from "@essenza/react";

import Logo from '../assets/img/logo-ftbcc.png';
import { LoginRecover } from "../view/login_recover";

function RecoverVistaController(c) {
    c.skin = RecoverVista;
}

export function RecoverVista() {
    const [ctx] = useVista(RecoverVistaController);
    return (
        <Vista context={ctx} >
            <div className='content-max-width'>
                <div className='block-middle-width centered'>
                    <Row className='content-max-width h-main padding-sm' align="middle">
                        <Col span={24} className="bg-sec padding-xl">
                            <img src={Logo} style={{ verticalAlign: "top", width: "194px" }} alt="Logo Banca bbc" className="elements-spacing-b16" />
                             <h1>
                                Procedura Recupero credenziali
                            </h1> 
                            <LoginRecover />
                        </Col>
                    </Row>
                </div>
            </div>
        </Vista>
    )
}