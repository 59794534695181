import { Button, Col, Form, Input, Row } from "antd";

import { useControl, useForm, Formix } from "@essenza/react";

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

function CategoryFormController(c) {
    c.skin = CategoryForm;
    c.command = {
        SAVE: async (path, { control, data }) => {
            //console.log("SAVE", source);
            let form = control.form("category-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                result.node.save().then((r) => {
                    if (path)
                        control.navigate(path, data);
                    else
                        control.closePopup();
                });
                //In futuro dovrà avvenire automaticamente in datagraph model, così come save su error ripristina dati originali
            }
        },
    }
}

export function CategoryForm({ source, label, npath, ndata }) {
    //const source = useGraph2(LessonModel, "item");
    const [control] = useControl(CategoryFormController);
    const form = useForm("category-form", source, control);
    return (
        <Formix control={control} form={form} layout='vertical' className="layout-form">
            <Form.Item label="Nome" name="tname">
                <Input placeholder="Nome Area"></Input>
            </Form.Item>
            <Row gutter={28}>
                <Col flex="auto">
                    <Form.Item className='elements-spacing-b0'>
                        <Button className='secondaryButton' onClick={() => control.navigate(-1, { state: '1' })}>
                            Annulla
                        </Button>
                    </Form.Item>
                </Col>
                <Col flex="none">
                    <Form.Item className='elements-spacing-b0'>
                        <Button className="primaryButton" onClick={() => control.execute("SAVE", npath, ndata)}>
                            {label || "Inserisci"}
                            <FrecciaBianca />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            {/* <Form.Item className='elements-spacing-b0'>
                <Button className="primaryButton" onClick={() => control.execute("SAVE", npath, ndata)}>
                    {label || "Inserisci"}
                    <FrecciaBianca />
                </Button>
            </Form.Item> */}
        </Formix>
    )
}