import { Col, Row } from "antd";

import { Vista, useVista } from "@essenza/react";

import { Login } from "../view/login_form";
import Logo from '../assets/img/logo-ftbcc.png';
import LoginScreen from '../assets/img/login-screen-ftbcc.png';

function LoginVistaController(c) {
    c.skin = LoginVista;
}

export function LoginVista() {
    const [ctx] = useVista(LoginVistaController);
    return (
        <Vista context={ctx} >
            <div className='content-max-width'>
                <div className='block-max-width centered'>
                    <Row className='content-max-width h-main padding-sm' align="middle">
                        <Col span={0} md={12} className="">
                            <div>
                                <img src={LoginScreen} style={{ width: "760px" }} alt="Copertina Login Banca bbc"></img>
                            </div>
                        </Col>
                        <Col span={24} md={12} className="bg-sec padding-xl">
                            <img src={Logo} style={{ verticalAlign: "top", width: "194px" }} alt="Logo Banca bbc" className="elements-spacing-b16" />
                            <h1>
                                Accedi a
                                {<br></br>}
                                Sempre con Te!
                            </h1>
                            <Login />
                        </Col>
                    </Row>
                </div>
            </div>
        </Vista>
    )
}