import { Row, Col, Button, Form, Input, Radio, Switch, Select, } from 'antd';
import React from "react";
import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';
import { Formix, FormixItem, useGraph, useForm, useControl } from '@essenza/react';
import { CourseModel } from '../models/CourseModel';

const { Option } = Select;

function CourseFormController(c) {
    c.skin = CourseForm;
    c.command = {
        SAVE: async (path, { control, data }) => {
            let form = control.form("course-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                result.node.save().then((r) => {
                    if (path)
                        control.navigate(path, data);
                    else
                        control.closePopup();
                });
            }
        },
    }
}

export function CourseForm({ confirmLabel, npath, ndata }) {
    const source = useGraph(CourseModel, "item");
    const [control] = useControl(CourseFormController);
    const form = useForm("course-form", source, control);
    console.log("COURSE FORM: ", source);
    return (
        <>
            <Formix control={control} form={form} layout='vertical' className="layout-form">
                <div style={{ width: '100%', marginTop: '24px' }} className="single-section-custom-0">
                    <FormixItem label="Titolo corso" name="title">
                        <Input placeholder="Inserisci qui il titolo del corso">
                        </Input>
                    </FormixItem>
                    <FormixItem label="Descrizione del corso" name="tdescription">
                        <Input.TextArea placeholder="Inserisci qui la descrizione del corso" rows={5}>
                        </Input.TextArea>
                    </FormixItem>
                    <Row gutter={28}>
                        <Col span={12}>
                            <FormixItem label="Categoria corso" name="idcategory">
                                <Radio.Group className="radio-margin">
                                    <Radio value={1}>Area tecnica</Radio>
                                    <Radio value={2}>Area commerciale</Radio>
                                    <Radio value={3}>Area manageriale</Radio>
                                    <Radio value={4}>Area comportamentale</Radio>
                                </Radio.Group>
                            </FormixItem>
                        </Col>
                        <Col span={12}>
                            <FormixItem label="Stato" name="state">
                                <Select>
                                    <Option value="B">Bozza</Option>
                                    <Option value="P">Pubblicato</Option>
                                </Select>
                            </FormixItem>
                            <FormixItem label="Propeuditicità lezioni" valuePropName="checked" name="progressive">
                                <Switch />
                            </FormixItem>
                        </Col>
                    </Row>
                    <Row gutter={28}>
                        <Col flex="auto">
                            <FormixItem className='elements-spacing-b0'>
                                <Button className='secondaryButton' onClick={() => control.navigate(-1, { state: '1' })}>
                                    Annulla
                                </Button>
                            </FormixItem>
                        </Col>
                        <Col flex="none">
                            <FormixItem className='elements-spacing-b0'>
                                <Button className="primaryButton" onClick={() => control.execute("SAVE", npath, ndata)}>
                                    {confirmLabel || 'Continua'}
                                    <FrecciaBianca />
                                </Button>
                            </FormixItem>
                        </Col>
                    </Row>
                    {/*                     <Form.Item className='elements-spacing-b0'>
                        <Button className="primaryButton" onClick={() => control.execute("SAVE", npath, ndata)}>
                        {confirmLabel || 'Continua'}
                            <FrecciaBianca />
                        </Button>
                    </Form.Item> */}
                </div>
            </Formix>
        </>
    )
}