import { DataGraph } from "@essenza/core";

const Type = { bool: 0, small: 1, string: 2, decimal: 3, double: 4, float: 5, int: 6, long: 7, date: 8 };
const bool = 0;
const small = 1;
const string = 2;
const decimal = 3;
const double = 4;
const float = 5;
const int = 6;
const long = 7;
const date = 8;
const money = 3;
const char = 2;

export function AppSchema() {
    DataGraph.setSchema({
        course: {id: int, title: string, tdescription: string, idcategory: int, state: char, category: string, progressive: bool, lessons: '[lesson]'},
        lesson: {id: int, tname: string, nduration: float, tdescription: string, idcourse: int, iorder: small },
        category: { id: int, tname: string},
        users: {id:int, name: string, surname: string, email: string, password: string, itype: int}
    });
}


