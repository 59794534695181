import { Vista, useVista, useGraph } from "@essenza/react";
import { SystemModel } from "../models/systemmodel";

function FormVistaController(c) {
    c.skin = FormVista;
}

export function FormVista() {
    const [ctx] = useVista(FormVistaController);
    const info = useGraph(SystemModel, "view").data;
    //const info = useLocation().state;
    if(!info) return null;
    return (
        <Vista context={ctx} >
            <div style={{ maxWidth: info?.maxWidth || '800px', marginTop: '24px' }} className="single-section-custom-2 centered">
                {info?.title && <h2>{info.title}</h2>}
                {info.view}
            </div>
        </Vista>
    )
}