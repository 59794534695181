import React from "react";
import { Col, Button, Form, Input, Row } from 'antd';
import * as yup from 'yup';

import { AppModel } from "@essenza/core";
import { Formix, FormixItem, useForm, useControl } from "@essenza/react";

import { ReactComponent as FrecciaBianca } from '../assets/svg/freccia-bianca.svg';

function ProfileFormController(c) {
    c.skin = ProfileForm;
    c.command = {
        SAVE: async (path, { control, model }) => {
            //console.log("SAVE", source);
            let form = control.form("profile-form");
            let result = await form.validate();
            console.log("PROFILE FORM VALIDATION", form, result);
            if (result.isValid) {
                if (result.isValid) {
                    model.request(AppModel, m=>m.updateProfile(result.data).then((r) => {
                        if (path)
                            control.navigate(path);
                        else
                            control.closePopup();
                    }));
                }
            }
        },
        CHANGE_PASSWORD: async (path, { control, model }) => {
            let form = control.form("password-form");
            let result = await form.validate();
            console.log("PASSWORD FORM VALIDATION", form, result);
            if (result.isValid) {
                model.request(AppModel, m=>m.changePassword(result.data).then((r) => {
                    if (path)
                        control.navigate(path);
                    else
                        control.closePopup();
                }));
            }
        }
    }
}

export function ProfileForm({ source, label, title, npath }) {
    //const source = useGraph2(LessonModel, "item");
    const [control] = useControl(ProfileFormController);
    const form = useForm("profile-form", source, control, null, yup.object({
        email: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
    }));
    const pform = useForm("password-form", source, control, null, yup.object({
        npassword: yup.string().required("Password è una informazione richiesta.").matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,24}$/,
            "Deve contenere Almeno 8 Caratteri, almeno una maiuscola, almeno una minuscola, almeno un umero ed almeno un carattere speciale"
        ),
        cpassword: yup.string().required("Conferma password richiesta.").test('passwords-match', 'Passwords non corrispondenti', function (value) {
            return this.parent.npassword === value;
        }),
    })); //, {itype: (v) => v === "1"}
    return (
        <>
            <div className="layout-form">
                <Row className="column-gap">
                    <Col className="single-section-profile">
                        <Formix control={control} form={form} layout='vertical' >
                            <h2 style={{marginTop: '70px'}}>Informazioni personali</h2>
                            <FormixItem label="Nome" name="name">
                                <Input>
                                </Input>
                            </FormixItem>
                            <FormixItem label="Cognome" name="surname">
                                <Input >
                                </Input>
                            </FormixItem>
                            <FormixItem label="E-mail" name="email">
                                <Input >
                                </Input>
                            </FormixItem>
                            <FormixItem>
                                <Button className="primaryButton" onClick={() => control.execute("SAVE", "/home")}>
                                    Aggiorna
                                    <FrecciaBianca />
                                </Button>
                            </FormixItem>
                        </Formix>
                    </Col>
                    <Col className="single-section-profile">
                        <Formix control={control} form={pform} layout='vertical' >
                            <h2 style={{marginTop: '70px'}}>Modifica password</h2>
                            <FormixItem label="Password attuale" name="password">
                                <Input.Password >
                                </Input.Password>
                            </FormixItem>
                            <FormixItem label="Nuova password" name="npassword">
                                <Input.Password>
                                </Input.Password>
                            </FormixItem>
                            <FormixItem label="Conferma password" name="cpassword">
                                <Input.Password>
                                </Input.Password>
                            </FormixItem>
                            <FormixItem>
                                <Button className="primaryButton" onClick={() => control.execute("CHANGE_PASSWORD", "/home")}>
                                    Aggiorna
                                    <FrecciaBianca />
                                </Button>
                            </FormixItem>
                        </Formix>
                    </Col>
                </Row>
            </div>
        </>
    )
}